/* |||||| Global, Page |||||| */

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--light-background-color);
    padding: 0;
    margin: 0;
    /* create new stacking context, otherwise strange behavior */
    z-index: 0;
}
.page-container {
    width: 100%;
    height: 100%;
}
.hide-visually {
    position: absolute;
    left: -999em;
}
* {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
}

/* ====== Fonts ====== */
@font-face {
    font-family: "ossl";
    src: url("assets/fonts/Open-Sauce-Sans/Open-Sauce-Sans/TTF/OpenSauceSans-Light.ttf");
}
@font-face {
    font-family: "ossr";
    src: url("assets/fonts/Open-Sauce-Sans/Open-Sauce-Sans/TTF/OpenSauceSans-Regular.ttf");
}
@font-face {
    font-family: "ossm";
    src: url("assets/fonts/Open-Sauce-Sans/Open-Sauce-Sans/TTF/OpenSauceSans-Medium.ttf");
}
@import url("https://fonts.googleapis.com/css2?family=Domine:wght@400;500;600;700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");
/* font-family: 'Domine', serif;
font-family: 'Merriweather', serif;
font-family: 'Rubik', sans-serif; */

:root {
    /* ------ Fonts ------ */
    --body-font-family: "ossl", sans-serif;
    --header-font-family: "domine";
    --title-font-family: "merriweather";
    /* ------ Colors ------ */
    --light-background-color: #f7f5f7;
    /* --light-background-color: #f7f6f9; */
    /* ^ other option: f7f6f9 */
    --dark-background-color: #423f49;
    /* ^ Other options = 494549, 2a2e2c, 4d4c4d, 5e5b64 */
    --global-chart-controls-background-color: #333;
    --low-temp: #41b1df;
    --high-temp: #ff7491;
}

/* ------ Override Defaults ------ */

button {
    padding: 0;
    margin: 0;
}
@media (hover: hover) {
    button:hover,
    .text-radio {
        cursor: pointer;
    }
    button:disabled:hover {
        cursor: default;
    }
}
input[type="radio"] {
    border: 1px solid red;
}

input[type="search"] {
    -webkit-appearance: none;
}

a {
    color: inherit;
    display: flex;
}
/* ====== Warning ====== */
.warning-drawer {
    display: flex;
    align-items: flex-end;
    width: 100%;
}
.warning-container {
    width: 100%;
    background-color: #f3cbcb;
    display: flex;
    font-size: 14px;
    padding: 2px 0;
    padding-right: 10px;
    box-sizing: border-box;
}
.warning {
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-grow: 1;
}
.close-warning {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    float: left;
    color: black;
    text-decoration: underline;
    text-decoration-color: #777;
}
.close-warning:hover {
    text-decoration-color: black;
}
.close-warning > svg {
    font-size: 18px;
}

/* ====== Rainbow Bar ====== */

.rainbow-bar {
    height: clamp(0.75rem, calc(0.67rem + 0.41vw), 0.94rem);
    /* ^ 15@1050, 12@320 */
}

/* |||||| Site Header |||||| */

header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header_title {
    font-size: clamp(1.81rem, calc(1.35rem + 2.29vw), 3rem);
    /* ^ 48px@1050, 29@320 */
    font-family: "merriweather";
    font-weight: 400;
    color: #282828;
    margin-top: clamp(0.81rem, calc(0.55rem + 1.33vw), 1.5rem);
    /* ^ 24@1050, 13@320 */
    margin-bottom: 0;
}

.header_subtitle {
    font-family: "Rubik";
    font-weight: 300;
    font-size: clamp(1.13rem, calc(0.93rem + 1vw), 1.44rem);
    /* ^ 23@820, 18@320 */
    font-size: clamp(1.13rem, calc(0.93rem + 1vw), 1.44rem);
    margin-top: clamp(0.25rem, calc(0.09rem + 0.82vw), 0.63rem);
    /* ^ 10p@1050, 4@320 */
    margin-bottom: clamp(0.88rem, calc(0.41rem + 2.33vw), 1.94rem);
    /* ^ 31@1050, 14@320 */
}

/* |||||| Global Chart |||||| */
/* ====== Standard View ====== */
#root {
    position: relative;
}
.global-chart-section2 {
    width: 100%;
    background-color: var(--dark-background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.global-chart-section-background2 {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-top: 9px solid;
    border-bottom: 16px solid;
    border-color: var(--light-background-color);
    background-color: transparent;
}
.global-chart-image-container2 {
    /* 950px image width - 2px image border */
    width: min(1048px, calc(100% - 2px));
    display: flex;
    aspect-ratio: 2000/1008;
    /* So that image animates over top of everything */
    z-index: 100;
    box-sizing: content-box;
}
.global-chart-image2 {
    border-radius: 6px 6px 0 0;
    margin: -1px -1px 0;
    box-sizing: content-box;
    border: 1px solid black;
    border-bottom-color: #333;
    width: 100%;
    aspect-ratio: 2000/1008;
}
.global-chart-image--fullscreen {
    max-height: 100%;
    aspect-ratio: 2000/1008;
    box-sizing: border-box;
}

/* ====== Fullscreen View ====== */
.global-chart-fullscreen-backdrop-filter {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 99;
    pointer-events: none;
}
.global-chart-fullscreen-container {
    z-index: 100;
    position: fixed;
    margin: 0px;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.global-chart-controls--fullscreen-drawer {
    width: min(100%, 1000px);
}
.global-chart-fullscreen-background {
    /* border: solid 2px red; */
    box-sizing: border-box;
    z-index: 100;
    position: fixed;
    margin: 0px;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    /* background-color: #000000099; */
    /* backdrop-filter: blur(20px) brightness(0.5); */
}

/* OLD */

/* ====== Global Chart Controls ====== */

/* ------ date ------ */
/* prevent collisions  with right controls */
@media screen and (max-width: 700px) {
    .global-chart-controls-date {
        margin-right: 128px;
    }
}
@media screen and (max-width: 390px) {
    .global-chart-controls-date {
        margin-right: 93px;
    }
}
.global-chart-controls-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    width: 330px;
    padding: 0 1.5em;
}
.global-chart-controls-date--left {
    width: 220px;
    padding: 0 8px;
}
.global-chart-title {
    display: flex;
    color: #eaeaea;
    font-family: "ossl";
    font-weight: 300;
}
.global-chart-title-divider {
    width: 2em;
    display: flex;
    justify-content: center;
    flex-shrink: 1;
}
.change-day {
    color: #fff;
    font-size: 20px;
    display: flex;
    position: relative;
}
.global-chart-title-divider {
    color: #777;
}
.global-chart-displayed-date {
    width: 6em;
    text-align: center;
}
/* ------ right ------ */
.global-chart-controls-right {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
}
.global-chart-controls-right > button {
    color: #eee;
    font-size: 22px;
    margin: 0 2px;
    display: flex;
    align-items: center;
}
.global-chart-controls-right .global-chart-toggle-key {
    height: 19px;
    border: solid 1px #999;
    font-size: 14px;
}
.global-chart-toggle-key {
    font-family: "ossl";
    font-weight: 300;
    border-radius: 5px;
    width: 70px;
}
@media screen and (max-width: 390px) {
    .global-chart-toggle-key {
        width: 35px;
    }
}
.global-chart-toggle-key line {
    stroke: #ddd;
    /* stroke: rgb(202, 100, 100); */
    stroke-width: 1px;
}

.global-chart-data-selection {
    display: flex;
    align-items: center;
}

/* ...... Data Selection ...... */
.global-chart-data-selection {
    color: #eaeaea;
    font-family: "ossr";
    font-weight: 300;
    font-size: 18px;
}
/* ...... Text Radio Controls ...... */
.text-radio-dimensioner {
    margin: 0 0.4em;
}
.text-radio {
    padding: 0 0.4em;
}

/* ...... Hover Effects ...... */
.change-day_icon--hover {
    display: none;
    color: #ddd;
    font-size: 20px;
    background-color: var(--global-chart-controls-background-color);
    /* transform: scale(1.2); */
}
@media (hover: hover) {
    .change-day:hover .change-day_icon--hover {
        display: block;
    }
    .change-day:hover .change-day_icon {
        display: none;
    }
}
/* ...... When disabled ...... */
.change-day:disabled {
    color: #666;
}
@media (hover: hover) {
    .change-day:disabled:hover {
        transform: none;
    }
    .change-day:disabled:hover .change-day_icon--hover {
        display: none;
    }
    .change-day:disabled:hover .change-day_icon {
        display: block;
    }
}

.global-chart_fullscreen-button {
    position: absolute;
    right: 3px;
    top: 1px;
    font-size: 22px;
    color: #fff;
}
@media (hover: hover) {
    .global-chart_fullscreen-button:hover {
        font-size: 24px;
        top: 0;
        right: 2px;
    }
}

/* .text-radio-label {
    transition: font-size 0.1s linear, opacity 0.1s linear;
} */

/* |||||| Search |||||| */

.search-container {
    width: min(90%, 35rem);
    margin: 4em 0em 4em;
    position: relative;
    z-index: 1;
}

.search-form {
    position: relative;
}

.search-input {
    display: flex;
    width: 100%;
    font-size: 22px;
    font-family: var(--body-font-family);
    border-radius: 0.6rem;
    padding: 0.25rem 0.6rem;
    /* margin: 0rem auto; */
    border: 1px solid #555;
    box-shadow: 0px 1px 4px #ddd;
    box-sizing: content-box;
    background-color: #fff;
}
/* Outline w/ radius doesn't work on Safari, using border instead */
.search-input:focus {
    border: 2px solid #e41c6f;
    margin: -1px;
    outline: 0;
}

.position-in-searchbar {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0.3rem;
    height: 100%;
}

.search-loader {
    right: 0.5rem;
}
.loader {
    width: 18px;
    height: 18px;
    border: 2px solid;
    border-color: #000 transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotate 2s linear infinite;
}

.clear-search {
    color: #222;
    font-size: 1.5rem;
}
@media (hover: hover) {
    .clear-search:hover {
        font-size: 1.7rem;
        right: 0.2rem;
    }
}
.search-container-background {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: var(--light-background-color); */
}

/* ...... Recents ...... */
.recent-locations {
    /* margin: 0.6em 0 0; */
    margin-bottom: 0;
    padding-left: 0.5em;
    display: flex;
    overflow: hidden;
}
.recent-locations > * {
    font-size: 20px;
    flex-shrink: 0;
}
.recent-item {
    display: flex;
    /* Changed to 1 by useVisibleOnlyInViewport */
    opacity: 0;
}
.recent-locations > .bold {
    font-family: "ossr";
}
.recent-locations a {
    color: #222;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: #ccc;
}
@media (hover: hover) {
    .recent-locations a:hover {
        text-decoration-color: #c90054;
        color: #000;
    }
}

/* ...... Current Location ...... */
.current-location-container {
    margin-top: 0.6em;
}
.current-location {
    display: flex;
    align-items: center;
    border: none;
    padding: 0 0.3rem;
    /* margin: 0; */
    text-decoration: none;
    background: none;
    color: #222;
    font-size: 20px;
    line-height: 1;
    cursor: pointer;
    text-align: center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: var(--body-font-family);
}
.current-location-loader {
    margin: 1px;
}
@media (hover: hover) {
    .current-location:hover {
        color: #a80046;
        border-radius: 1rem;
    }
    .current-location:hover > svg {
        color: #c90054;
    }
}
/* ...... Search Errors ...... */
.search-error-veil {
    background-color: var(--light-background-color);
    display: flex;
    flex-direction: column;
    width: 100%;
}
.search-error {
    z-index: -2;
    position: absolute;
    display: flex;
    align-items: center;
    font-size: 20px;
    border: 1px solid rgb(255, 90, 90);
    border-radius: 6px;
    background-color: #ffdfdf;
    padding: 1px 2px;
}
.search-error-icon {
    color: rgb(255, 47, 47);
    font-size: 24px;
    margin-right: 0.1em;
}
.search-error-text {
    margin: 0 0.2em;
    padding: 0;
}
.search-error-coverage-link {
    display: inline;
    text-decoration-color: #555;
}
@media (hover: hover) {
    .search-error-coverage-link:hover {
        color: #000;
        text-decoration-thickness: 2px;
        text-decoration-color: #000;
    }
}
.close-error {
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #333;
}
@media (hover: hover) {
    .close-error:hover {
        color: #000;
    }
}
/* ------ Override Defaults ------ */
/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}
input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

/* |||||| Forecasts |||||| */

.forecast-width-provider {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
}

/* .chartViewport {
    gives the chart it's own stacking context
    border: 1px solid red;
    z-index: 1;
} */
.forecast-container {
    display: flex;
    flex-direction: column;
    /* width: min(800px, 100%) !important; */
}
/* ====== Temporary Changes ====== */
/* Experimental changes to be implemented less sloppily */
.topPad-background {
    width: 100%;
    z-index: 11;
    background-color: var(--light-background-color);
    position: absolute;
    top: 0;
}
.bottomPad-background {
    width: 100%;
    z-index: 11;
    background-color: var(--light-background-color);
    position: absolute;
    bottom: 0;
}
.forecast-chart-background {
    filter: blur(2px);
    /* filter: blur(2px) opacity(0.75); */
}

/* ====== Header ====== */

.forecast-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 8px;
}

/* ------ Header Title ------ */
.forecast-location {
    margin: 0;
    font-family: var(--header-font-family);
    font-size: 30px;
    font-weight: 500;
    color: #282828;
}

/* ------ Header Buttons ------ */
.forecast-header-buttons {
    display: flex;
    align-items: center;
    padding: 0 0 5px;
    height: 23px;
    color: red;
}
.forecast-header-buttons > * {
    display: flex;
    align-items: flex-end;
    font-size: 20px;
    color: black;
}
.pinned-icon {
    color: rgb(80, 81, 109);
}
.pin-icon--hovered {
    color: #7b7c96;
    display: none;
}
.pinned-icon--hovered {
    color: #7b7c96;
    display: none;
}
@media (hover: hover) {
    .pin-button:hover .pin-icon--hovered {
        display: inline;
    }
    .pin-button:hover .pin-icon {
        display: none;
    }
    .pin-button:hover .pinned-icon--hovered {
        display: inline;
    }
    .pin-button:hover .pinned-icon {
        display: none;
    }
}
.map-button {
    font-size: 22px;
    margin: 0 6px 0 4px;
}
.map-icon--hovered {
    display: none;
    color: rgb(80, 81, 109);
}
@media (hover: hover) {
    .map-button:hover .map-icon {
        display: none;
    }
    .map-button:hover .map-icon--hovered {
        display: inline;
    }
}
.toggle-key {
    width: 90px;
}
@media screen and (max-width: 390px) {
    .toggle-key {
        width: 42px;
    }
}
.toggle-key line {
    /* stroke: rgb(128, 14, 14); */
    stroke: black;
    stroke-width: 1px;
}

/* ====== Alerts ====== */

.forecast-alert-container {
    /* backdrop-filter: saturate(60%) brightness(0.9); */
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: none;
    pointer-events: none;
    /* background-color: #000; */
}
.forecast-alert-message {
    padding: 6px 11px;
    margin: 0;
    border: 2px solid #555;
    border-radius: 12px;
    background-color: var(--light-background-color);
    /* background-color: rgb(226, 223, 233); */
    font-size: 22px;
    font-family: ossl;
    font-weight: 300;
    color: #111;
}

/* ====== Chart ====== */
/* See chartLayout.png for layout glossary */

.forecast-chart-container {
    position: relative;
    /* width: 100%; */
    width: calc(100%-2px);
    border: 1px solid #333;
    display: flex;
    box-shadow: 0px 1px 4px #bbb;
    overflow: hidden;
}

/* ------ Chart Left ------ */

.chart-left-container {
    flex-shrink: 0;
    position: relative;
}
.chart-header {
    font-size: 16px;
    font-family: "ossr";
    font-weight: 300;
    color: #222;
}
.chart-header-space-fix {
    display: inline-block;
    width: 0.06em;
}

/* ...... Top Left ...... */

.chart-header--days {
    width: 100%;
    padding: 1px 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    box-sizing: border-box;
    border-bottom: 1px solid #222;
    position: absolute;
    top: 0;
    right: 0;
}

/* ...... Center Left ...... */

.y-title {
    position: absolute;
    left: 4px;
    top: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    font-family: "rubik";
    font-weight: 400;
    color: #333;
    font-size: 18px;
    text-decoration: underline;
    text-underline-offset: 0px;
}
.temp-axis {
    /* Fixes improper placement of axis line */
    transform: translate(0px, -0.5px);
}
.y-label {
    font-family: "ossl";
    font-size: 14px;
    text-anchor: end;
    fill: #222;
    transform: translate(-2px, 5px);
}
.y-axis-and-labels {
    fill: "transparent";
    flex-shrink: 0;
}

/* ...... Bottom Left ...... */
.bottom-left-container {
    border-top: solid 1px #222;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.chart-header--times {
    margin-top: 0.4em;
}

/* ------ Chart Middle ------ */

.only-visible-in-viewport {
    transition: opacity 0.1s linear;
}
.chart-middle-viewport {
    position: relative;
    width: 100%;
    flex-shrink: 1;
    height: 100%;
}
.chart-middle-full {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.x-grid,
.x-ticks {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
    width: 100%;
    height: 100%;
}
.x-gridline,
.x-tick {
    margin-left: -0.5px;
}

/* ...... Top Middle ...... */

.day-label {
    margin: 0 8px;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    transition: opacity 0.15s linear;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    font-weight: 300;
    font-size: 16px;
}
/* ...... Center Middle ...... */

.forecast-data-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}
.forecast-data-line {
    z-index: 13;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}
.y-grid {
    z-index: 11;
    position: sticky;
    left: 0;
    top: 0;
}

.x-gridline {
    position: absolute;
    border-left: 1px dotted #000;
}
.x-gridline--midnight {
    border-left: 1px solid #333;
}

/* ...... Bottom Middle ...... */

.min-max-labels-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}
.x-tick {
    height: 5px;
    width: 1px;
    background-color: #222;
    position: absolute;
    display: flex;
    flex-flow: column;
    overflow: visible;
    align-items: center;
    /* Initial opacity=0, changed to 1 by intersection observer */
    opacity: 0;
    transition: opacity 0.1s linear;
}
.x-tick > * {
    position: relative;
    /* INVESTIGATE: Why 6.5px? Otherwise not lined up with time and temp headers */
    top: 6.5px;
}
.x-labels-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    border-radius: 5px;
}
.x-labels-container--min,
.x-labels-container--max {
    transform: translateY(-2px);
    padding: 0px 1px 1px;
}
.x-labels-container--min {
    border: 2px solid var(--low-temp);
}
.x-labels-container--max {
    border: 2px solid var(--high-temp);
}
.x-label--min,
.x-label--max {
    display: flex;
    align-items: center;
    margin: 0 0.4em;
    /* Initial opacity=0, changed to 1 by intersection observer */
    opacity: 0;
    transition: opacity 0.1s linear;
}
.x-label--min > svg {
    color: var(--low-temp);
}
.x-label--max > svg {
    color: var(--high-temp);
}

/* ------ Days ------ */

.forecast-days {
    display: flex;
    z-index: 12;
    position: absolute;
    top: 0;
    left: 0;
}
.forecast-day {
    display: flex;
    position: relative;
}
.forecast-day-filter {
    position: absolute;
    width: 100%;
    right: 0;
    box-sizing: border-box;
}

/* ......Hover Effects...... */

@media (hover: hover) {
    .forecast-days:hover .forecast-day:not(:hover) .forecast-day-filter.daily {
        /* ^ container hovered, days not hovered, only when daily */
        /* backdrop-filter: brightness(0.94); */
        backdrop-filter: brightness(0.85);
    }
    .forecast-days:hover .forecast-day:hover .forecast-day-filter.daily {
        /* ^ container hovered, day hovered, only when daily */
        /* backdrop-filter: brightness(1.02); */
        backdrop-filter: brightness(1.03);
    }
}

/* ------ Scrollbar ------ */

.forecast-chart-container .os-scrollbar {
    height: 10px;
}
.forecast-chart-container .os-scrollbar-track {
    /* needs !important to override the high specificity of the defaults  */
    background-color: #ccc !important;
    height: 3px;
}
.forecast-chart-container .os-scrollbar-handle {
    height: 7px;
    /* needs !important to override the high specificity of the defaults  */
    background-color: #bfbfbf !important;
    box-sizing: border-box;
    border: 1px solid #aaa;
    position: relative;
    bottom: 2px;
}

/* ...... Hover and Active Effects ...... */
/* When hovered */
@media (hover: hover) {
    .forecast-chart-container .os-scrollbar:hover .os-scrollbar-handle {
        height: 9px;
        bottom: 3px;
        border: 2px solid #888;
    }
}
/* When active */
.forecast-chart-container .os-scrollbar-handle.active {
    height: 9px;
    bottom: 3px;
    border: 2px solid #888;
}
/* Can't select track when handle is active */

/* ====== Key ====== */

.forecast-key-container {
    display: flex;
    align-items: flex-end;
    height: 0;
    overflow: hidden;
}
.forecast-key {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    /* place above shadow of forecast container */
    z-index: 5;
    overflow: hidden;
    /* border-radius: 0 0 5px 5px; */
    border: solid 1px black;
    border-top: none;
}
.forecast-key-category {
    /* display: flex; */
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 180px;
    padding: 3px 4px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.forecast-key-category-label {
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    font-family: "ossr";
    font-weight: 300;
    color: #191919;
}

/* ====== Footer ====== */
.forecast-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 6px 8px 0;
}
.forecast-footer-buttons {
    display: flex;
    align-items: center;
    padding: 0 0 5px;
    height: 23px;
}
.forecast-footer-buttons-label {
    padding: 0;
    margin: 0;
    font-family: "ossl";
    font-weight: 300;
    font-size: 20px;
}
.forecast-footer-buttons > * {
    display: flex;
    align-items: flex-end;
    font-size: 20px;
    top: 3px;
    margin-left: 0.5em;
    color: black;
}
.button-switch {
    position: relative;
    height: 21px;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    font-size: 18px;
    border-radius: 6px;
    border: 1px solid #222;
}
@media (hover: hover) {
    .button-switch:hover {
        /* outline: 1px solid #999; */
        color: white;
        background-color: rgb(103, 105, 140);
        /* border-color: #e41c6f; */
    }
}
.button-switch-label {
    position: absolute;
}
.toggle-units {
    width: 1.8em;
}
.toggle-thermal-index {
    width: 3.2em;
}
.toggle-forecast-timescale {
    width: 62px;
}

/* |||||| About |||||| */

.about-article {
    /* for visual centering */
    /* padding: 2em 0 0 1.5rem; */
    /* max-width: 30rem; */
    justify-content: center;
    display: flex;
    flex-direction: column;
    /* border: 1px solid black; */
    padding-bottom: 2em;
    /* margin-top: 1em; */
}
.about-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;
}
.about-section {
    max-width: 30rem;
    /* for visual centering */
    padding-left: 1.5rem;
    /* border: 1px solid #333; */
    /* border-radius: 20px; */
    margin: 1em 0;
    /* box-shadow: 0px 1px 4px #bbb; */
}
@media screen and (max-width: 600px) {
    .about-section {
        padding-right: 1rem;
    }
}
.about-section-header {
    /* margin-top: 3.5rem; */
    margin-bottom: 0.1em;
    /* padding-bottom: 0.2rem; */
    font-family: var(--header-font-family);
    font-size: 30px;
    font-weight: 500;
    color: #282828;
}
.about-paragraph {
    font-family: var(--body-font-family);
    font-size: 18px;
    letter-spacing: -0.003rem; /* looks better to my eye */
    line-height: 1.8rem;
    margin: 0.8em 0;
}
.extra-bold {
    font-family: "ossm";
}

/* |||||| Modal |||||| */

.modal-background {
    z-index: 100;
    position: fixed;
    margin: 0px;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: stretch;
    /* background-color: #000000099; */
    /* backdrop-filter: blur(20px) brightness(0.5); */
}

.close-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 30px;
    right: 20px;
    font-size: 30px;
    color: #f8f8f8;
    /* background-color: #333; */
}
.close-modal > svg {
    /* background-color: #333; */
    border-radius: 15px;
}

/* |||||| Footer |||||| */
footer {
    width: 100%;
    position: relative;
    z-index: 10;
}

.contact-info {
    z-index: -1;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: -45px;
    border: 1px solid #222;
    border-radius: 5px;
    padding: 4px 3px 4px 10px;
    margin: 0 1em;
    font-size: 18px;
}
.close-contact-info {
    display: flex;
    align-items: center;
    font-size: 24px;
    padding: 0;
    padding-left: 3px;
    margin: 0;
    color: #555;
}
@media (hover: hover) {
    .close-contact-info:hover {
        color: #222;
        margin: -2px;
        font-size: 28px;
    }
}

.footer-links-container {
    background-color: #222;
    align-self: stretch;
    align-items: bottom;
    display: flex;
    justify-content: right;
    padding: 0.2rem 1rem 0.4rem;
    color: #fff;
    flex-wrap: wrap;
}
.footer-link {
    color: #fff;
}
@media (hover: hover) {
    .footer-link:hover {
        transform: scale(1.03);
        text-decoration: underline;
    }
}

.coverage-map {
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 0;
    object-fit: contain;
}

@media screen and (min-width: 1200px) {
    .coverage-map {
        margin: 0 20px;
    }
    .global-chart_image--modal {
        margin: 0 20px;
    }
}

/* |||||| Accessability |||||| */

@media screen and (prefers-reduced-motion: reduce) {
    * {
        /* Very short durations means JavaScript that relies on events still works */
        animation-duration: 0.001ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.001ms !important;
    }
}

/* ====== OLD ====== */
button {
    background-color: #00000000;
    /* color: #fff; */
    border: none;
}
/* backdrop */
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000e1;
    display: flex;
    justify-content: center;
    align-items: center;
}

body {
    margin: 0;
    padding: 0;

    /* display: flex; */
    /* background-color: rgb(245, 243, 244); */
    /* background: rgb(255, 241, 229); */
    /* background: linear-gradient(
        180deg,
        rgba(255, 241, 229, 1) 0%,
        rgba(250, 177, 190, 1) 100%
    ); */
    height: 58rem;
    font-family: var(--body-font-family);
    background-color: var(--light-background-color);

    /* background: linear-gradient(
        0deg,
        rgb(238, 242, 249) 0%,
        rgba(247, 245, 247, 1) 75vh
    ); */
}

h4 {
    margin: 0;
}

.about-article {
    /* background: rgb(239, 243, 250);
    border-radius: 20px;
    border: 3px solid #555;
     */
    /* background: linear-gradient(
        0deg,
        rgb(238, 242, 249) 0%,
        rgba(247, 245, 247, 1) 75vh
    ); */
    /* border: 2px solid #888;
    border-radius: 30px;
    padding: 0 3em; */
    margin-bottom: 3em;
}
/* header {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
} */

/* header > h4 {
    width: 100%;
    text-align: center;
} */

.plain-button {
    display: flex;
    align-items: center;
    border: none;
    padding: 0;
    margin: 0 0.1em;
    text-decoration: none;
    background: none;
    color: #222;
    font-size: 24px;
    line-height: 1;
    cursor: pointer;
    text-align: center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
@media (hover: hover) {
    .plain-button:hover {
        color: #bb054b;
        border-radius: 1rem;
    }
}

form {
    display: flex;
    justify-content: center;
    align-items: center;
}

.error {
    text-align: center;
}

#spacingFix {
    /* necessary because when only using align-items doesn't appear visually centered along same horizontal axis as icon */
    padding: 0 0 0.1rem 0.4rem;
}
.icon {
    /* padding: 0 0.3rem 0 0; */
    font-size: 20px;
    /* animation-name: rotate; */
    animation-duration: 0.5s;
    animation-iteration-count: 2;
    /* retain position once animation stops */
    animation-fill-mode: forwards;
    transform-origin: 50% 50%;
    animation-timing-function: linear;
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
